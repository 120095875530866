import i18next from 'i18next';

export default {
  // Option pour modifier le comportement du clic sur un titre d'article dans le search :
  // 'newTab' > ouvre l'info dans un nouvel onglet (pas de bouton pour ouvrir dans le complet)
  // 'complete' > ouvre le complet
  CLICK_ON_DOCS_TITLE_BEHAVIOR: 'newTab',
  // Affichage du complet au centre des résultats pleine page
  COMPLETE_ON_RESULTS_FULL_PAGE: false,

  override_all: {
    DASHBOARDS_PARAMS_PER_BASE: {
      default: {
        WIDGETS: [
          {
            i: 'publicationDates',
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            facets: 'PUB_DATE#auto_date_histogram|300 _key:asc',
            aggregates: ['agg_PUB_DATE'],
            type: 'spline',
            title: i18next.t('dashboard.widget.documents_published_by_dates'),
          },
          {
            i: 'languages',
            x: 0,
            y: 1,
            w: 3,
            h: 1,
            humanizeLangs: true,
            facets: 'LANGUAGE',
            aggregates: ['agg_LANGUAGE'],
            type: 'pie',
            title: i18next.t('dashboard.widget.languages'),
          },
          {
            i: 'topSources',
            x: 3,
            y: 1,
            w: 3,
            h: 1,
            facets: 'DOMAIN.verbatim',
            aggregates: ['agg_DOMAIN.verbatim'],
            facetmax: 50,
            type: 'datatable',
            title: i18next.t('dashboard.widget.top_50_domain'),
          },
          {
            i: 'countryTopic',
            x: 0,
            y: 2,
            w: 3,
            h: 1,
            facets: 'QES_CountryTopicCode.verbatim',
            aggregates: ['agg_QES_CountryTopicCode.verbatim'],
            type: 'map',
            mindoccount: 1,
            facetmax: 10000,
            facetmax2: 10000,
            title: i18next.t('dashboard.widget.countries_mentioned'),
          },
          {
            i: 'locations',
            x: 3,
            y: 2,
            w: 3,
            h: 1,
            facets: 'QES_Location.verbatim',
            aggregates: ['agg_QES_Location.verbatim'],
            type: 'bar',
            title: i18next.t('dashboard.widget.places_mentioned_in_documents'),
          },
          {
            i: 'cooccurrenceCompanyConceptPerson',
            x: 0,
            y: 3,
            w: 6,
            h: 1,
            facets: 'QES_Company.verbatim,QES_ConceptCategorized.verbatim,QES_Person.verbatim',
            aggregates: ['agg_QES_Company.verbatim'],
            facetmax: 15,
            facetmax2: 3,
            mindoccount: 1,
            type: 'sankey',
            title: i18next.t('dashboard.widget.cooccurrences_company_concepts_persons'),
          },
          {
            i: 'companies',
            x: 0,
            y: 4,
            w: 2,
            h: 1,
            facets: 'QES_Company.verbatim',
            aggregates: ['agg_QES_Company.verbatim'],
            type: 'pie',
            title: i18next.t('dashboard.widget.compagnies_mentioned'),
          },
          {
            i: 'persons',
            x: 2,
            y: 4,
            w: 2,
            h: 1,
            facets: 'QES_Person.verbatim',
            aggregates: ['agg_QES_Person.verbatim'],
            type: 'pie',
            title: i18next.t('dashboard.widget.persons_mentioned'),
          },
          {
            i: 'organizations',
            x: 4,
            y: 4,
            w: 2,
            h: 1,
            facets: 'QES_Organization.verbatim',
            aggregates: ['agg_QES_Organization.verbatim'],
            type: 'pie',
            title: i18next.t('dashboard.widget.organizations_mentioned'),
          },
          {
            i: 'concepts',
            x: 0,
            y: 5,
            w: 2,
            h: 1,
            facets: 'QES_Concept.verbatim',
            aggregates: ['agg_QES_Concept.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            title: i18next.t('dashboard.widget.concepts'),
          },
          {
            i: 'business_concepts',
            x: 2,
            y: 5,
            w: 2,
            h: 1,
            facets: 'QES_ConceptBusiness.verbatim',
            aggregates: ['agg_QES_ConceptBusiness.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            title: i18next.t('dashboard.widget.business_concepts'),
          },
          {
            i: 'security_concepts',
            x: 4,
            y: 5,
            w: 2,
            h: 1,
            facets: 'QES_ConceptSecurity.verbatim',
            aggregates: ['agg_QES_ConceptSecurity.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            title: i18next.t('dashboard.widget.security_concepts'),
          },
          {
            i: 'justice_concepts',
            x: 0,
            y: 6,
            w: 2,
            h: 1,
            facets: 'QES_ConceptJustice.verbatim',
            aggregates: ['agg_QES_ConceptJustice.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            title: i18next.t('dashboard.widget.justice_concepts'),
          },
          {
            i: 'risk_concepts',
            x: 2,
            y: 6,
            w: 2,
            h: 1,
            facets: 'QES_ConceptRisk.verbatim',
            aggregates: ['agg_QES_ConceptRisk.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            title: i18next.t('dashboard.widget.risk_concepts'),
          },
          {
            i: 'unofficial_organizations',
            x: 4,
            y: 6,
            w: 2,
            h: 1,
            facets: 'QES_Organonoff.verbatim',
            aggregates: ['agg_QES_Organonoff.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            title: i18next.t('dashboard.widget.unofficial_organizations'),
          },
          {
            i: 'cooccurrences',
            x: 0,
            y: 7,
            w: 6,
            h: 2,
            facetmax: 200,
            facetmax2: 5,
            mindoccount: 1,
            type: 'networkgraph',
            title: i18next.t('dashboard.widget.cooccurrences_graph'),
          },
        ],

        COOCCURRENCES_FIELDS: [
          { name: 'Personnes', value: 'QES_Person.verbatim', active: true },
          { name: 'Sociétés', value: 'QES_Company.verbatim', active: true },
          { name: 'Concepts', value: 'QES_ConceptCategorized.verbatim', active: true },
          { name: 'Organisations non officielles', value: 'QES_Organonoff.verbatim' },
          { name: 'Organisations', value: 'QES_Organization.verbatim' },
          { name: 'Évènements', value: 'QES_Event.verbatim' },
        ],
      },
    },
  },
  DYNAMIC_DASHBOARD: false,
};
