const en = {
  en: {
    translation: {
      dashboard: {
        widget: {
          cooccurrences_company_concepts_persons: 'Co-occurrences of companies, concepts and persons',
          risk_concepts: 'Risk Concepts',
        },
      },
    },
  },
};

export default en;
