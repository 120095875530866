const fr = {
  fr: {
    translation: {
      dashboard: {
        widget: {
          cooccurrences_company_concepts_persons: 'Cooccurrences sociétés, concepts et personnes',
          risk_concepts: 'Concepts Risque',
        },
      },
    },
  },
};

export default fr;
